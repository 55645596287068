import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const useBroadcastChannel = () => {
  const [message, setMessage] = useState<string | null>(null);
  const uuid = uuidv4();

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("deptapparel");

    const handleMessage = (event: any) => {
      const [id, message] = event.data.split("__");
      if (id === uuid) return;
      setMessage(message);
    };

    broadcastChannel.addEventListener("message", handleMessage);

    return () => {
      broadcastChannel.removeEventListener("message", handleMessage);
      broadcastChannel.close();
    };
  }, [uuid]);

  const postMessage = (message: string) => {
    const broadcastChannel = new BroadcastChannel("deptapparel");
    broadcastChannel.postMessage(`${uuid}__${message}`);
  };

  return { message, postMessage };
};

export default useBroadcastChannel;
