import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * { box-sizing: border-box; }

  a {
    color: inherit;
    text-decoration: none;
  }

  body,
  html {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button,
  input,
  select {
    appearance: none;
    background: none;
    border: 0;
    margin: 0;
    outline: none;
    padding: 0;
  }

  button {
    color: inherit;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }

  img {
    display: block;
    height: auto;
    max-width: none;
    width: 100%;
  }

  @font-face {
    font-family: "abcWalterNeue";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/fonts/ABCWalter-NeueMager.otf");
  }

  @font-face {
    font-family: "abcWalterNeue";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/ABCWalter-NeueNormal.otf");
  }

  @font-face {
    font-family: "abcWalterNeue";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/fonts/ABCWalter-NeueMittel.otf");
  }

  @font-face {
    font-family: "valentineLL";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/ValentineLL-Regular.otf");
  }

  ::selection {
  background-color: rgb(255, 74, 0);
  /* color: white; */
}
`;
