import { ReactNode } from "react";
import { StyledMainWrapper } from "./MainWrapper.styles";

export interface MainWrapperProps {
  children: ReactNode;
}

const MainWrapper = ({ children }: MainWrapperProps) => (
  <StyledMainWrapper>{children}</StyledMainWrapper>
);

export default MainWrapper;
