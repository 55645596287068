// import { ApplicationInsights } from "@microsoft/applicationinsights-web";
// import {
//   ReactPlugin,
//   AppInsightsErrorBoundary,
// } from "@microsoft/applicationinsights-react-js";
import type {
  ActionFunction,
  LinksFunction,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { getEnv } from "~/config";
import { ShopifyProvider } from "~/shopify/components/ShopifyProvider";
import type { Handle } from "~/@types";
import { pathedRoutes } from "./other-routes.server";
import GoogleTagManager from "~/components/GoogleTagManager";
import Ticker from "./components/Ticker";
import dayjs from "dayjs";
import Footer from "./components/Footer";
import { validator } from "./components/NewsletterSignupForm";
import { validationError } from "remix-validated-form";
import Nav from "./components/Nav";
import { BASE_PAGE_TITLE, ROUTES } from "./routes/const";
import StickyLogo from "./components/StickyLogo";
import { CountryCode } from "@shopify/hydrogen/dist/esnext/storefront-api-types";
import MainWrapper from "./components/MainWrapper";
import AppWrapper from "./components/AppWrapper";
import Heading from "./components/Heading";
import Text from "./components/Text";
import PageWrapper from "./components/PageWrapper";
import Button from "./components/Button";
import { defaultCountryCode } from "./i18n-config";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { GlobalStyle } from "./styles/globalStyle";
import useBroadcastChannel from "./hooks/useBroadcastChannel";
import { useEffect } from "react";

const defaultBrowserHistory = {
  url: "/",
  location: { pathname: "" },
  state: { url: "" },
  listen: () => {
    return;
  },
};

let browserHistory = defaultBrowserHistory;
if (typeof window !== "undefined") {
  browserHistory = { ...browserHistory, ...window.history };
  browserHistory.location.pathname = browserHistory?.state?.url;
}

export const links: LinksFunction = () => [
  {
    href: "/android-chrome-192x192.png",
    rel: "icon",
    sizes: "192x192",
    type: "image/png",
  },
  {
    href: "/android-chrome-512x512.png",
    rel: "icon",
    sizes: "512x512",
    type: "image/png",
  },
  {
    href: "/apple-touch-icon.png",
    rel: "apple-touch-icon",
  },
  { href: "/favicon.ico", rel: "icon" },
  {
    href: "/favicon-16x16.png",
    rel: "icon",
    sizes: "16x16",
    type: "image/png",
  },
  {
    href: "/favicon-32x32.png",
    rel: "icon",
    sizes: "32x32",
    type: "image/png",
  },
  {
    href: "/site.webmanifest",
    rel: "manifest",
  },
];

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: BASE_PAGE_TITLE,
  keywords: "DEPT,blog,store,agency",
  "twitter:creator": "@deptagency",
  "twitter:site": "@deptagency",
  "twitter:title": BASE_PAGE_TITLE,
  "twitter:description": BASE_PAGE_TITLE,
  viewport: "width=device-width,initial-scale=1",
  "og:image": "https://www.deptapparel.com/og-image.png",
});

type LoaderData = {
  GLOBALS: string;
  GA_TRACKING_ID: string | null;
  PUBLICLY_AVAILABLE_ORIGIN: string;
  country?: string;
  //APPINSIGHTS_INSTRUMENTATIONKEY: string;
};

export const loader: LoaderFunction = async ({ request, context }) => {
  // because this is called for every route, we'll do an early return for anything
  // that has a other route setup. The response will be handled there.
  if (pathedRoutes[new URL(request.url).pathname]) {
    return new Response();
  }

  const country = context.country as string | undefined;

  return json<LoaderData>({
    country: country || defaultCountryCode,
    GA_TRACKING_ID: getEnv("GA_TRACKING_ID", { default: null }),
    PUBLICLY_AVAILABLE_ORIGIN: getEnv("PUBLICLY_AVAILABLE_ORIGIN"),
    // APPINSIGHTS_INSTRUMENTATIONKEY: getEnv("APPINSIGHTS_INSTRUMENTATIONKEY", {
    //   default: "",
    // }),
    GLOBALS: JSON.stringify({
      SENTRY_DSN: getEnv("SENTRY_DSN", { default: null }),
    }),
  });
};

// const useAppInsights = () => {
//  const { APPINSIGHTS_INSTRUMENTATIONKEY } = useLoaderData<LoaderData>();
//   const reactPlugin = new ReactPlugin();
//   const appInsights = new ApplicationInsights({
//     config: {
//       autoTrackPageVisitTime: true,
//       enableAutoRouteTracking: true,
//       extensions: [reactPlugin],
//       extensionConfig: {
//         [reactPlugin.identifier]: { history: browserHistory },
//       },
//       instrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY || "",
//     },
//   });
//   if (typeof window !== "undefined") {
//     appInsights.loadAppInsights();
//     appInsights.trackPageView();
//   }

//   return reactPlugin;
// };

const useGoogleAnalytics = () => {
  const { GA_TRACKING_ID } = useLoaderData<LoaderData>();

  if (!GA_TRACKING_ID?.length) {
    return null;
  }

  return GA_TRACKING_ID;
};

export const handle: Handle = {
  // In the handle export, we could add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  i18n: "common",
};

export const action: ActionFunction = async ({ request }) => {
  // Validate form data
  const form = await validator.validate(await request.formData());

  // Check for errors
  if (form.error) {
    return validationError(form.error);
  }

  // Get form data
  const { email } = form.data;

  /**
   * Do something with the data here
   */

  // Return JSON response and consume with `useActionData()`
  return json({
    email,
  });
};

const App = () => {
  // These variables are defined separately so we can remove them more easily
  const { GLOBALS, country: storedCountry } = useLoaderData<LoaderData>();
  const { message } = useBroadcastChannel();
  const countryCode = (storedCountry?.toLowerCase() ?? "nl") as CountryCode;

  const gaTrackingId = useGoogleAnalytics();
  //const reactPlugin = useAppInsights();
  const { pathname } = useLocation();
  const isHome = pathname === ROUTES.HOME;
  const isShop = pathname === ROUTES.SHOP;
  const backgroundColor = isHome ? theme.colors.orange : theme.colors.white;

  useEffect(() => {
    if (message && message.startsWith("cart:")) {
      window.location.reload();
    }
  }, [message]);

  return (
    <html
      lang="en"
      style={{
        backgroundColor,
      }}
    >
      <head>
        <meta name="theme-color" content={backgroundColor}></meta>
        <Meta />
        <Links />
        {gaTrackingId && <GoogleTagManager gaTrackingId={gaTrackingId} />}
        {typeof document === "undefined" ? "__STYLES__" : null}
      </head>
      <body
        style={{
          backgroundColor,
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {/* <AppInsightsErrorBoundary
            onError={() => <h1>Something went wrong</h1>}
            appInsights={reactPlugin}
          > */}
          <AppWrapper $isHome={isHome}>
            <ShopifyProvider countryCode={countryCode}>
              <Ticker
                color={isHome ? "white" : "orange"}
                lines={[
                  ["(DPT)", "DR01", dayjs().format("DD.MM.YYYY")],
                  ["Worldwide"],
                ]}
              />
              <MainWrapper>
                <Nav />
                <PageWrapper $hasNav>
                  <Outlet />
                </PageWrapper>
                <Footer />
                <StickyLogo $isSticky={isHome || isShop} />
              </MainWrapper>
            </ShopifyProvider>
            <ScrollRestoration />
            <script
              suppressHydrationWarning
              dangerouslySetInnerHTML={{
                __html: `window.GLOBALS=${GLOBALS}`,
              }}
            />
            <Scripts />
            <LiveReload />
          </AppWrapper>
          {/* </AppInsightsErrorBoundary> */}
        </ThemeProvider>
      </body>
    </html>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ErrorBoundary = ({ error }: { error: any }) => {
  console.error(error);

  return (
    <html lang="en">
      <head>
        <title>Oh no! - DEPT® Apparel</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Heading>An error ocurred</Heading>
        <Text>{error.message}</Text>
        <Text>{error.cause}</Text>
        <Scripts />
      </body>
    </html>
  );
};

export const CatchBoundary = () => {
  const { t } = useTranslation();
  const caught = useCatch();
  console.error(caught);

  return (
    <html lang="en">
      <head>
        <title>Unexpected error - DEPT® Apparel</title>
        <Meta />
        <Links />
        {typeof document === "undefined" ? "__STYLES__" : null}
      </head>
      <body>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <AppWrapper $isError>
            <Ticker
              color="orange"
              lines={[
                ["(DPT)", "DR01", dayjs().format("DD.MM.YYYY")],
                ["Worldwide"],
              ]}
            />
            <MainWrapper>
              <PageWrapper
                $hasNav={false}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: `${theme.space[64]} ${theme.space[16]} 0`,
                }}
              >
                <Heading
                  as="h1"
                  scrambleOptions={{
                    durationRangeEnd: 1000,
                    durationRangeStart: 500,
                  }}
                  size="h1"
                  style={{ marginBottom: theme.space[16] }}
                >
                  {caught.status.toString()}
                </Heading>
                <Text
                  size="label-val"
                  style={{ marginBottom: theme.space[40] }}
                >
                  {caught.data}
                </Text>
                <a href={ROUTES.HOME} rel="nofollow">
                  <Button as="span" inverted>
                    {t("404 button label")}
                  </Button>
                </a>
                <StickyLogo $isSticky $isWhite style={{ marginTop: "auto" }} />
              </PageWrapper>
            </MainWrapper>
            <Scripts />
          </AppWrapper>
        </ThemeProvider>
      </body>
    </html>
  );
};

export default App;
