import {
  DesktopHeading,
  EmailLink,
  InstagramLink,
  MobileHeading,
  StyledFooter,
  TextWrapper,
  StyledEmailAdress,
} from "./Footer.styles";
import Text from "~/components/Text";
import CountrySelector from "~/components/CountrySelector";
import Heading from "../Heading";

const Footer = () => (
  <StyledFooter>
    <TextWrapper>
      <MobileHeading size="h6">DEPT APPAREL</MobileHeading>
      <DesktopHeading size="h5">DEPT APPAREL</DesktopHeading>
      <DesktopHeading $hasMargin size="h5">
        (DPT)
      </DesktopHeading>
      <InstagramLink
        href="https://www.instagram.com/deptapparel"
        rel="noreferrer"
        target="_blank"
      >
        <Heading as="span" size="h6">
          IG
        </Heading>
        <Heading as="span" size="h5">
          Instagram
        </Heading>
      </InstagramLink>
    </TextWrapper>
    <StyledEmailAdress>
      <EmailLink
        href="mailto:deptapparel@deptagency.com"
        target="_blank"
        rel="noreferrer"
      >
        <Text as="span" size="label-val">
          deptapparel@deptagency.com
        </Text>
      </EmailLink>
      <CountrySelector />
    </StyledEmailAdress>
  </StyledFooter>
);

export default Footer;
