import { createStyledBreakpointsTheme } from "styled-breakpoints";
import { DefaultTheme } from "styled-components";

export const breakpoints = {
  widescreen: "1440px",
  desktop: "1024px",
  mobile: "375px",
  base: "0px",
} as const;

export const primaryTheme = {
  colors: {
    black: "rgb(0, 0, 0)",
    grey: "rgb(248, 248, 248)",
    orange: "rgb(255, 74, 0)",
    white: "rgb(255, 255, 255)",
  },
  fonts: {
    abcWalterNeue: "abcWalterNeue, Arial, Helvetica, sans-serif",
    valentineLL: "valentineLL",
  },
  fontSizes: {
    10: "0.625rem",
    12: "0.75rem",
    14: "0.875rem",
    16: "1rem",
    18: "1.125rem",
    24: "1.5rem",
    32: "2rem",
    36: "2.25rem",
    48: "3rem",
    60: "3.75rem",
    80: "5rem",
    96: "6rem",
  },
  fontWeights: {
    thin: "300",
    normal: "400",
    medium: "500",
    semiBold: "600",
  },
  letterSpacings: {
    default: "-0.03em",
  },
  lineHeights: {
    16: "1rem",
    20: "1.25rem",
    24: "1.5rem",
    32: "2rem",
    56: "3.5rem",
    64: "4rem",
    81: "5.0625rem",
  },
  sizes: {
    8: "0.5rem",
    16: "1rem",
    24: "1.5rem",
    32: "2rem",
    56: "3.5rem",
    64: "4rem",
  },
  space: {
    gridColumnGap: "1rem",
    4: "0.25rem",
    8: "0.5rem",
    12: "0.75rem",
    16: "1rem",
    24: "1.5rem",
    32: "2rem",
    36: "2.25rem",
    40: "2.5rem",
    48: "3rem",
    56: "3.5rem",
    64: "4rem",
    72: "4.5rem",
    80: "5rem",
    88: "5.5rem",
    96: "6rem",
    128: "8rem",
    144: "9rem",
    176: "11rem",
    192: "12rem",
    240: "15rem",
    256: "16rem",
  },
  zIndices: {
    1: 1,
    2: 10,
    3: 100,
  },
} as const;

export const theme: DefaultTheme = {
  ...createStyledBreakpointsTheme({
    breakpoints,
  }),
  ...primaryTheme,
};
