import { ComponentProps, ReactNode } from "react";
import { StyledPageWrapper } from "./PageWrapper.styles";
import PageTransition from "../PageTransition/PageTransition";

export interface PageWrapperProps
  extends ComponentProps<typeof StyledPageWrapper> {
  children: ReactNode;
}

const PageWrapper = ({ children, ...props }: PageWrapperProps) => (
  <PageTransition>
    <StyledPageWrapper {...props}>{children}</StyledPageWrapper>
  </PageTransition>
);

export default PageWrapper;
