import styled, { css } from "styled-components";

interface StyledAppWrapperProps {
  $isError?: boolean;
  $isHome?: boolean;
}

export const StyledAppWrapper = styled.div<StyledAppWrapperProps>`
  ${({ $isError, theme }) =>
    $isError &&
    css`
      background-color: ${theme.colors.black};
      color: ${theme.colors.white};
    `}

  ${({ $isHome, theme }) =>
    $isHome &&
    css`
      background-color: ${theme.colors.orange};
    `}
`;
