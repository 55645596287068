import { StyledIcon } from "./Icon.styles";
import { ReactSVG } from "react-svg";

export type IconNames = "chevron-down";

export interface IconProps {
  className?: string;
  name: IconNames;
}

const Icon = ({ className, name }: IconProps) => (
  <StyledIcon className={className}>
    <ReactSVG src={`/svg/${name}.svg`} />
  </StyledIcon>
);

export default Icon;
