import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { NAV_HEIGHT_DESKTOP, NAV_HEIGHT_MOBILE } from "../Nav";
import { TICKER_HEIGHT } from "~/components/Ticker";

export const StyledPageTransition = styled(motion.main)`
  min-height: calc(100vh - ${NAV_HEIGHT_MOBILE}px - ${TICKER_HEIGHT}px);

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    min-height: calc(100vh - ${NAV_HEIGHT_DESKTOP}px - ${TICKER_HEIGHT}px);
  }
`;

export const LoaderGrid = styled.div`
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
`;

export const StyledLoaderSquare = styled.div`
  background: ${({ theme }) => theme.colors.orange};

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
    `}
`;
