import type { ReactNode } from "react";
import { CartProvider, ShopifyContext } from "@shopify/hydrogen";
import { defaultCountryCode, defaultLanguageCode } from "~/i18n-config";
import {
  SHOPIFY_STORE,
  SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  SHOPIFY_STOREFRONT_API_VERSION,
} from "~/config";
import { CountryCode } from "@shopify/hydrogen/dist/esnext/storefront-api-types";
import useBroadcastChannel from "~/hooks/useBroadcastChannel";

export interface ShopifyProviderProps {
  children: ReactNode;
  countryCode: CountryCode;
}

export const ShopifyProvider = ({
  children,
  countryCode,
}: ShopifyProviderProps) => {
  const { postMessage } = useBroadcastChannel();
  /* Notes about what we're doing here:
  1. We need a ShopifyContext in order for hooks to work (like useProduct, which in turns calls useShop)
  2. We can't use ShopifyProvider because it expects a server request context to be present from Hydrogen
  3. We can't use ShopifyProviderClient because we can't include the file on the server because it's named ShopifyProvider.client.js and Remix excludes all *.client.js from the server bundle */
  return (
    <ShopifyContext.Provider
      value={{
        defaultLanguageCode,
        defaultCountryCode,
        storefrontId: undefined,
        storeDomain: `${SHOPIFY_STORE}.myshopify.com`,
        storefrontToken: SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        storefrontApiVersion: SHOPIFY_STOREFRONT_API_VERSION,
      }}
    >
      <CartProvider
        countryCode={countryCode}
        onLineAdd={() => postMessage("cart:add")}
        onLineUpdate={() => postMessage("cart:update")}
        onLineRemove={() => postMessage("cart:remove")}
      >
        {children}
      </CartProvider>
    </ShopifyContext.Provider>
  );
};
