import styled, { css } from "styled-components";
import { TICKER_HEIGHT } from "../Ticker";
import { NAV_HEIGHT_DESKTOP, NAV_HEIGHT_MOBILE } from "../Nav";

interface StyledPageWrapperProps {
  $hasNav: boolean;
}

export const StyledPageWrapper = styled.div<StyledPageWrapperProps>`
  min-height: calc(100vh - ${TICKER_HEIGHT}px);

  ${({ $hasNav, theme }) =>
    $hasNav &&
    css`
      min-height: calc(100vh - ${TICKER_HEIGHT}px - ${NAV_HEIGHT_MOBILE}px);

      ${theme.breakpoints.up("desktop")} {
        min-height: calc(100vh - ${TICKER_HEIGHT}px - ${NAV_HEIGHT_DESKTOP}px);
      }
    `}
`;
