import { css, styled } from "styled-components";
import { layoutPadding } from "~/styles/mixins";
import Heading from "../Heading";

export const StyledFooter = styled.footer`
  ${layoutPadding};
  margin-bottom: ${({ theme }) => theme.space[16]};
  margin-top: ${({ theme }) => theme.space[80]};

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    margin-top: ${({ theme }) => theme.space[256]};
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space[16]};

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    justify-content: initial;
  }
`;

export const MobileHeading = styled(Heading)`
  ${({ theme }) => theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

interface DesktopHeadingProps {
  $hasMargin?: boolean;
}

export const DesktopHeading = styled(Heading)<DesktopHeadingProps>`
  ${({ theme }) => theme.breakpoints.down("desktop")} {
    display: none;
  }

  ${({ $hasMargin, theme }) =>
    $hasMargin &&
    css`
      margin: 0 auto 0 ${theme.space[96]};
    `}
`;

export const EmailLink = styled.a`
  margin-right: ${({ theme }) => theme.space[16]};

  &:hover {
    text-decoration: underline;
  }
`;

export const InstagramLink = styled.a`
  > span:first-of-type {
    ${({ theme }) => theme.breakpoints.up("desktop")} {
      display: none;
    }
  }

  > span:last-of-type {
    ${({ theme }) => theme.breakpoints.down("desktop")} {
      display: none;
    }
  }
`;

export const StyledEmailAdress = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => theme.space[12]} 0;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
