import { ComponentProps } from "react";
import { Icon, SelectElement, StyledSelect } from "./Select.styles";

export type SelectProps = ComponentProps<typeof SelectElement>;

const Select = ({ children, size = "medium", ...props }: SelectProps) => (
  <StyledSelect>
    <SelectElement {...props} size={size}>
      {children}
    </SelectElement>
    <Icon name="chevron-down" />
  </StyledSelect>
);

export default Select;
