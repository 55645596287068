import { ComponentProps } from "react";
import { Logo, PixelatedLogo, StyledStickyLogo } from "./StickyLogo.styles";
import { Link } from "@remix-run/react";
import { ROUTES } from "~/routes/const";

interface StickyLogoProps extends ComponentProps<typeof StyledStickyLogo> {}

const StickyLogo = (props: StickyLogoProps) => (
  <StyledStickyLogo {...props}>
    <Link to={ROUTES.HOME}>
      <Logo src="/logo.svg" />
      <PixelatedLogo src="/pixelated-logo.svg" />
    </Link>
  </StyledStickyLogo>
);

export default StickyLogo;
