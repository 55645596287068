/**
 * This component is used to animate page transitions
 * using the Framer Motion library and can be customized
 * to your liking.
 * @see https://www.framer.com/docs/animate-presence/
 */
import { useLocation } from "@remix-run/react";
import { ReactNode, useEffect, useState } from "react";
import { useWindowSize } from "~/hooks/useWindowSize";
import {
  LoaderGrid,
  StyledLoaderSquare,
  StyledPageTransition,
} from "./PageTransition.styles";
import { theme } from "~/styles/theme";

const PageTransition = ({ children }: { children: ReactNode }) => {
  const { width, height } = useWindowSize();
  const location = useLocation();
  const maxRandomNumber = width! < 400 ? 3 : 5;

  const SQUARE_SIZE = width! < 400 ? 35 : 90;

  const columnsNumber = Math.round(width! / SQUARE_SIZE);
  const rowsNumber = Math.round(height! / SQUARE_SIZE);
  const isBlackBackground =
    location.pathname !== "/shop" && location.pathname !== "/";

  const [availableSquares, setAvailableSquares] = useState<number[]>([]);

  const getRandomSquares = (num: number) => {
    const randomSquares = [];

    while (randomSquares.length < num) {
      const randomIndex = Math.floor(Math.random() * availableSquares.length);
      const randomSquare = availableSquares[randomIndex];
      randomSquares.push(randomSquare);
    }

    return randomSquares;
  };

  useEffect(() => {
    if (!width) return;
    const initialSquares = [...Array(columnsNumber * rowsNumber).keys()];
    setAvailableSquares(initialSquares);
  }, [width, location.pathname]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const randomNumber = Math.floor(Math.random() * maxRandomNumber) + 1;
      const randomSquaresToBeRemoved = getRandomSquares(randomNumber);

      setAvailableSquares((prevSquares) =>
        prevSquares.filter(
          (square) => !randomSquaresToBeRemoved.includes(square)
        )
      );
    }, 5);

    return () => {
      clearTimeout(timeout);
    };
  }, [availableSquares]);

  return (
    <StyledPageTransition key={useLocation().pathname}>
      {availableSquares.length > 0 && (
        <LoaderGrid
          style={{
            gridTemplateColumns: `repeat(${columnsNumber}, 1fr)`,
            gridTemplateRows: `repeat(${rowsNumber}, 1fr)`,
          }}
        >
          {columnsNumber &&
            [...Array(columnsNumber * rowsNumber).keys()].map((_, index) => (
              <StyledLoaderSquare
                hidden={!availableSquares.includes(index)}
                key={index}
                style={{
                  background: isBlackBackground ? "black" : theme.colors.orange,
                }}
              />
            ))}
        </LoaderGrid>
      )}
      {children}
    </StyledPageTransition>
  );
};

export default PageTransition;
