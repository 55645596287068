import { ReactSVG } from "react-svg";
import styled, { css, keyframes } from "styled-components";
import { layoutPadding } from "~/styles/mixins";

interface StyledStickyLogoProps {
  $isSticky: boolean;
  $isWhite?: boolean;
}

interface LogoProps {
  src: any;
}

export const StyledStickyLogo = styled.div<StyledStickyLogoProps>`
  ${layoutPadding};
  padding-bottom: ${({ theme }) => theme.space[16]};
  position: relative;
  width: 100%;

  ${({ $isSticky }) =>
    $isSticky &&
    css`
      bottom: 0;
      position: sticky;
    `};

  ${({ $isWhite }) =>
    $isWhite &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `};
`;

const logoAnimation = keyframes`
  0% { opacity: 1; }
  40% { opacity: 0; }
  41% { opacity: 1; }
  70% { opacity: 0; }
  72% { opacity: 1; }
  73% { opacity: 0; }
  74% { opacity: 1; }
`;

const pixelatedLogoAnimation = keyframes`
  0% { opacity: 0; }
  40% { opacity: 1; }
  41% { opacity: 0; }
  70% { opacity: 1; }
  72% { opacity: 0; }
  73% { opacity: 1; }
  74% { opacity: 0; }
`;

export const Logo = styled(ReactSVG)<LogoProps>`
  animation: ${logoAnimation} 10s infinite step-end;
`;

export const PixelatedLogo = styled(ReactSVG)`
  animation: ${pixelatedLogoAnimation} 10s infinite step-end;
  left: ${({ theme }) => theme.space[8]};
  position: absolute;
  right: ${({ theme }) => theme.space[8]};
  top: 0;

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    left: ${({ theme }) => theme.space[16]};
    right: ${({ theme }) => theme.space[16]};
  }
`;
