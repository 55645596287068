import IconComponent from "~/components/Icon";
import styled, { css } from "styled-components";

export const StyledSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Icon = styled(IconComponent)`
  height: 20px;
  pointer-events: none;
  position: absolute;
  width: 20px;
`;

interface SelectElementProps {
  size?: string;
}

export const SelectElement = styled.select<SelectElementProps>`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: block;
  font-family: ${({ theme }) => theme.fonts.abcWalterNeue};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1;
  text-transform: uppercase;
  width: 100%;

  ${({ size, theme }) =>
    size === "small" &&
    css`
      font-size: ${theme.fontSizes[12]};
      padding: 2px 20px 2px 0;

      [& +${Icon}] {
        right: 0;
        top: 0;
      }
    `}

  ${({ size, theme }) =>
    size === "medium" &&
    css`
      border: 1px solid ${theme.colors.black};
      font-size: ${theme.fontSizes[14]};
      padding: 13px ${theme.space[16]};

      [& +${Icon}] {
        right: ${theme.space[16]};
        top: 11;
      }
    `}
`;
