import { ComponentProps, ReactNode } from "react";
import { StyledAppWrapper } from "./AppWrapper.styles";

export interface AppWrapperProps
  extends ComponentProps<typeof StyledAppWrapper> {
  children: ReactNode;
}

const AppWrapper = ({ children, ...props }: AppWrapperProps) => (
  <StyledAppWrapper {...props}>{children}</StyledAppWrapper>
);

export default AppWrapper;
