import {
  ChangeEvent,
  ComponentProps,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import Select from "../Select";
import { StyledCountrySelector } from "./CountrySelector.styles";
import {
  getSupportedCurrenciesWithLanguages,
  CurrencyWithLanguage,
} from "../../shopify";
import { defaultCountryCode } from "~/i18n-config";

export type CountrySelectorProps = ComponentProps<typeof StyledCountrySelector>;

export const getCookieValueByName = (name: string) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : "";
};

const CountrySelector = (props: CountrySelectorProps) => {
  const { i18n } = useTranslation();
  const [supportedCountries, setSupportedCountries] = useState<
    CurrencyWithLanguage[]
  >([]);
  const [selected, setSelected] = useState<CurrencyWithLanguage>();

  useEffect(() => {
    getSupportedCurrenciesWithLanguages()
      .then((d) => d && setSupportedCountries(d))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const storedCountry = getCookieValueByName("country");
    setSelected(
      supportedCountries?.find(
        ({ countryCode }) => countryCode === storedCountry
      ) ??
        supportedCountries?.find(
          ({ countryCode }) => countryCode === defaultCountryCode
        )
    );
  }, [i18n, supportedCountries]);

  const setSelectedCountry = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLSelectElement>) => {
      const selectedCountry = supportedCountries.find(
        ({ countryCode }) => countryCode === currentTarget.value
      );

      if (selectedCountry) {
        setSelected(selectedCountry);
        i18n.changeLanguage(selectedCountry.i18nCode);
        document.cookie = `lng=${selectedCountry.i18nCode};path=/;max-age=604800`;
        document.cookie = `country=${selectedCountry.countryCode};path=/;max-age=604800`;
        window.location.reload();
      }
    },
    [i18n, supportedCountries]
  );

  return (
    <StyledCountrySelector {...props}>
      <Select
        onChange={setSelectedCountry}
        size="small"
        value={selected?.countryCode}
      >
        {supportedCountries.map(
          ({ countryCode, countryName, currencySymbol, currencyCode }) => (
            <option key={countryCode} value={countryCode}>
              {countryName} ({currencyCode} {currencySymbol})
            </option>
          )
        )}
      </Select>
    </StyledCountrySelector>
  );
};

export default CountrySelector;
